import React from 'react'
import { prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ info }) => {
  if (!info || info.isHide) return null

  return (
    <div className={styles.info}>
      <div className={styles.wrap}>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: info.title }} />
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: info.description }} />
        <div className={styles.items}>
          {info.items &&
            info.items.map(item => (
              <div className={styles.item} key={item._key}>
                <div className={styles.image}>
                  <img
                    {...srcSetProps(sanityImageUrl(item.image))}
                    className={styles.imageSrc}
                    alt={item.image?.caption}
                  />
                </div>
                <div className={styles.text}>
                  <p className={styles.itemTitle}>{item.title}</p>
                  <p className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
